/***
 *
 *   ORDERS
 *   View sales orders
 *
 **********/

import React, {Fragment, useContext, useEffect, useState} from 'react';
import {
  AppLayoutContext, Button,
  Loader,
  useAPI
} from 'components/lib';
import {useNavigate, useParams} from "react-router-dom";
import Style from "../../components/hero/hero.tailwind";
import axios from "axios";

export function RedirectOrder(props){

  let navigate = useNavigate();
  const { orderId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const order = useAPI('/api/orders/vtiger/' + orderId);
  console.log(order);

  // Add buttons to nav header (needed even if empty, to clear stale buttons from prev pages)
  const [navButtons, setNavButtons] = useContext(AppLayoutContext);
  useEffect(() => {
    setNavButtons([]);
  }, []);


  useEffect(() => {
    if (order.data && order.data.id) {
     navigate(`/order/${order.data.id}`);
    }
  }, [order]);

  return (
    <Fragment>

      { order.loading ?
        <Loader /> :
        <div>
          { (!order.data || !order.data.id) &&
            <>
              <p>Order hasn't been imported into Datastand yet.</p>
              <p>Do you want to import and sync this order? <span className={"text-gray-400"}>(Vtiger id:{orderId})</span></p>

              { !isLoading && <Button action={async () => {
                setIsLoading(true);
                const response = await axios({

                  method: 'get',
                  url: '/api/orders/sync/' + orderId,

                });
                // TODO: validate failure/errors
                navigate(`/order/${response.data.data.id}`);
              }} text='Import Sales Order' small className={ Style.button + " my-2 mb-3 bg-orange-500 hover:bg-orange-700"}/>}
              { isLoading && <span className={"text-gray-600 text-md loading-trailing-dots"} ><Loader classNames={`w-6 h-6 inline-block mr-2`} />Syncing Order...</span>}
            </>
          }
        </div>
      }
    </Fragment>
  )
}
